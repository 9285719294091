var TEST_HOST = "/webapi"; //测试
var PROD_HOST = "/api"; //生产

var DEV_HOST = TEST_HOST;

var API_HOST = DEV_HOST;
var host = location.hostname;
if (host.match(/zxnenergy\.com/)) {
  API_HOST = PROD_HOST;
} else if (host.match(/vpclub\.cn/)) {
  API_HOST = TEST_HOST;
}
